import React from "react";
import Helmet from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";

const DisclaimerPage = ({ data: { site } }) => {
  return (
    <Layout>
      <Helmet>
        <title>Disclaimer — {site.siteMetadata.title}</title>
        <meta
          name="description"
          content={"Disclaimer page of " + site.siteMetadata.description}
        />
      </Helmet>
      <div className="two-grids">
        <p>
          Mini Roofs Racks is a participant in the Amazon EU Associates
          Programme, an affiliate advertising programme designed to provide a
          means for sites to earn advertising fees by advertising and linking to
          Amazon.co.uk.
        </p>
        <p>
          Mini Roofs Racks is a participant in the ebay Partner Network
          Programme, an affiliate advertising programme designed to provide a
          means for sites to earn advertising fees by advertising and linking to
          eBay.co.uk.
        </p>
      </div>
      <Link to="/">&larr; Back</Link>
    </Layout>
  );
};
export default DisclaimerPage;
export const pageQuery = graphql`
  query DisclaimerPageQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;
